import React from 'react'
import { FAQ, Footer, Hero, LinkingSteps, PromotionBanner } from 'src/components'

const TwitchDrops: React.FC = () => {
    return (
        <React.Fragment>
            <Hero />
            <LinkingSteps />
            <FAQ />
            <PromotionBanner />
            <Footer />
        </React.Fragment>
    )
}

export default TwitchDrops
