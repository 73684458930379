import React from 'react'
import { ActionLayout, NewsletterBenefits, NewsletterRegistration } from 'src/components'

const Register: React.FC = () => {
    return (
        <ActionLayout
            leftColumn={<NewsletterBenefits />}
            rightColumn={<NewsletterRegistration />}
        />
    )
}

export default Register
