import React from 'react'
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { validators, Validators, ValidationResult } from 'src/utils'

interface IInputField {
    name: string
    value: string
    label: string
    placeholder: string
    validator: keyof Validators
    onChange: (value: string) => void
    onReset: () => void
    onSave: () => void
    editDisabled?: boolean
    saveDisabled?: boolean
    helperText?: string
    endText?: string
    loading?: boolean
}

const InputField: React.FC<IInputField> = ({
    name,
    value,
    label,
    placeholder,
    validator,
    onChange,
    onReset,
    onSave,
    editDisabled,
    saveDisabled,
    helperText,
    endText,
    loading
}) => {
    const [readonly, setReadonly] = React.useState<boolean>(true)

    const { valid, errorText } = React.useMemo((): ValidationResult => {
        return validators[validator](value)
    }, [validator, value])

    function handleSave(): void {
        setReadonly(true)
        onSave()
    }

    function handleResetClick(): void {
        setReadonly(true)
        onReset()
    }

    return (
        <TextField
            id={name}
            label={label}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            disabled={readonly || loading}
            error={!valid && !readonly}
            helperText={readonly ? helperText : errorText}
            variant="standard"
            InputProps={{
                sx: {
                    '& .MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: 'rgba(255, 255, 255, 0.75)'
                    }
                },
                endAdornment: (
                    <React.Fragment>
                        {endText && (
                            <InputAdornment position="start">
                                <Box
                                    component="span"
                                    sx={{ ml: 1, color: 'grey.500', fontSize: 'body2.fontSize' }}
                                >
                                    {endText}
                                </Box>
                            </InputAdornment>
                        )}
                        <InputAdornment position="end">
                            <React.Fragment>
                                {readonly || (
                                    <Box sx={{ color: 'error.light', mr: 1 }}>
                                        <IconButton color="inherit" onClick={handleResetClick}>
                                            <FontAwesomeIcon
                                                color="inherit"
                                                icon={faCircleXmark}
                                                size="xs"
                                            />
                                        </IconButton>
                                    </Box>
                                )}
                                {readonly ? (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        disabled={editDisabled || loading}
                                        onClick={() => setReadonly(false)}
                                    >
                                        {loading ? (
                                            <FontAwesomeIcon spin icon={faSpinner} />
                                        ) : (
                                            'Edit'
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="text"
                                        color="success"
                                        disabled={!valid || loading || saveDisabled}
                                        onClick={handleSave}
                                    >
                                        {loading ? (
                                            <FontAwesomeIcon spin icon={faSpinner} />
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                )}
                            </React.Fragment>
                        </InputAdornment>
                    </React.Fragment>
                )
            }}
            sx={{
                width: '100%',
                '& .MuiInput-root': {
                    py: 1
                },
                '& .MuiInput-root.Mui-disabled::before': {
                    borderBottomStyle: 'solid'
                },
                '& .MuiFormHelperText-root': {
                    color: 'primary.light'
                }
            }}
        />
    )
}

export default InputField
