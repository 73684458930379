import React from 'react'
import Routes from './Routes'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { AccountContextProvider, SnackbarContextProvider } from 'src/context'
import { Snackbar } from 'src/components'
import { theme } from 'src/config'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarContextProvider>
                    <AccountContextProvider>
                        <Routes />
                        <Snackbar />
                    </AccountContextProvider>
                </SnackbarContextProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
