import { createTheme } from '@mui/material/styles'

import grunge from 'src/assets/grunge.jpg'

const machineStd = {
    fontFamily: 'MachineStd, sans-serif',
    letterSpacing: '0.03em',
    lineHeight: 1,
    backgroundColor: '#ffffff',
    backgroundImage: `url(${grunge})`,
    backgroundPosition: '50% 50%',
    backgroundSize: '512px 512px',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    textFillColor: 'transparent',
    WebkitTextFillColor: 'transparent'
}

const robotoMono = {
    fontFamily: 'Roboto Mono, monospace',
    fontWeight: 400
}

const palette: Record<string, any> & { mode: 'light' | 'dark' } = {
    mode: 'dark',
    action: {
        disabled: '#808080',
        disabledBackground: '#cecece',
        disabledOpacity: 1
    },
    common: {
        black: '#0b0b0b',
        white: '#ffffff'
    },
    background: {
        default: '#0b0b0b'
    },
    primary: {
        main: '#358b93',
        light: '#5df8ff',
        dark: '#749097'
    },
    secondary: {
        main: '#1f3037',
        light: '#273c45',
        dark: '#19272d'
    }
}

export const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    backgroundColor: palette.common.black,
                    boxShadow: 'none'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    ...robotoMono,
                    fontWeight: 600,
                    textTransform: 'uppercase'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: palette.common.white
                    }
                }
            }
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        }
    },
    palette,
    shape: {
        borderRadius: 0
    },
    typography: {
        ...robotoMono,
        h1: machineStd,
        h2: machineStd,
        h3: machineStd,
        h4: machineStd,
        h5: machineStd,
        h6: machineStd,
        body1: robotoMono,
        body2: robotoMono,
        button: robotoMono,
        caption: robotoMono,
        overline: robotoMono,
        subtitle1: robotoMono,
        subtitle2: robotoMono
    }
})
