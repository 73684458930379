import React from 'react'
import { Box, ButtonBase, Container, Link, Typography, useTheme } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
    faDiscord,
    faFacebookF,
    faInstagram,
    faTiktok,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'
import { links, Links } from 'src/config'

import iconDarktide from 'src/assets/icon-darktide.png'
import logoFatshark from 'src/assets/logo-fatshark.png'
import logoWarhammer from 'src/assets/logo-warhammer-official.png'

interface ISocialMediaButton {
    href: string
    icon: IconDefinition
}

const menuLinks: Array<keyof Links> = [
    'home',
    'news',
    'community',
    'gallery',
    'wishlist',
    'merch',
    'privacyPolicy'
]

const SocialMediaButton: React.FC<ISocialMediaButton> = ({ href, icon }) => {
    const theme = useTheme()

    return (
        <ButtonBase
            component="a"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                m: '4px',
                flexGrow: 0,
                flexShrink: 0,
                height: 40,
                width: 40,
                borderRadius: '50%',
                border: `1px solid ${theme.palette.common.white}`,
                backgroundColor: 'common.white',
                transition: 'all .3s ease-in-out',
                color: 'common.black',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',

                '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'common.white'
                }
            }}
        >
            <FontAwesomeIcon size="lg" icon={icon} />
        </ButtonBase>
    )
}

const Footer: React.FC = () => {
    const theme = useTheme()
    const year = new Date().getFullYear()

    const imgStyles: React.CSSProperties = {
        marginRight: 20,
        userSelect: 'none',
        pointerEvents: 'none'
    }

    return (
        <Container component="footer">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: { xs: 'flex-start', md: 'space-between' }
                }}
            >
                <Box sx={{ mt: 8, mb: { sx: 0, md: 4 } }}>
                    <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                        <img width={60} src={iconDarktide} alt="Darktide Icon" style={imgStyles} />
                        <img width={60} src={logoFatshark} alt="Fatshark Logo" style={imgStyles} />
                        <img
                            width={100}
                            src={logoWarhammer}
                            alt="Official Warhammer Logo"
                            style={imgStyles}
                        />
                    </Box>
                    <Box component="nav" sx={{ mb: 3, display: 'flex', flexWrap: 'wrap' }}>
                        {menuLinks.map((link, index) => (
                            <Box
                                key={links[link].name}
                                sx={{
                                    mr: 1,
                                    mb: 1,
                                    display: 'flex',
                                    ...(index < menuLinks.length - 1 && {
                                        borderRight: `1px solid ${theme.palette.common.white}`,
                                        pr: 1
                                    })
                                }}
                            >
                                <Link
                                    underline="hover"
                                    color="common.white"
                                    href={links[link].href}
                                    sx={{
                                        lineHeight: 1,
                                        fontSize: '0.75rem',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {links[link].name}
                                </Link>
                            </Box>
                        ))}
                    </Box>
                    <Typography
                        component="p"
                        variant="caption"
                        pr={1}
                        sx={{
                            color: 'grey.600',
                            lineHeight: 1.4,
                            fontWeight: 400,
                            maxWidth: '38rem'
                        }}
                    >
                        Warhammer 40,000: Darktide © Copyright Games Workshop Limited {year}.
                        Darktide, the Darktide logo, GW, Games Workshop, Space Marine, 40K,
                        Warhammer, Warhammer 40,000, 40,000, the ‘Aquila’ Double-headed Eagle logo,
                        and all associated logos, illustrations, images, names, creatures, races,
                        vehicles, locations, weapons, characters, and the distinctive likeness
                        thereof, are either © or ™, and/or © Games Workshop Limited, variably
                        registered around the world, and used under licence. All rights reserved to
                        their respective owners.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: { xs: 4, md: 12 },
                        mb: 6,
                        mx: { xs: 'auto', md: 0 },
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <SocialMediaButton href={links['facebook'].href} icon={faFacebookF} />
                    <SocialMediaButton href={links['twitter'].href} icon={faTwitter} />
                    <SocialMediaButton href={links['instagram'].href} icon={faInstagram} />
                    <SocialMediaButton href={links['tiktok'].href} icon={faTiktok} />
                    <SocialMediaButton href={links['discord'].href} icon={faDiscord} />
                </Box>
            </Box>
        </Container>
    )
}

export default Footer
