import React from 'react'
import Layout from './Layout'
import Menu from './Menu'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DecoratedCorners } from 'src/components'

export namespace DashboardLayoutTypes {
    export interface Section {
        content: React.ReactNode
        title?: string
    }

    export interface Sections {
        sections: Array<Section>
    }
}

const Section: React.FC<DashboardLayoutTypes.Section> = ({ content, title }) => {
    return (
        <DecoratedCorners size={12} thickness={3} color="primary.light" sx={{ mb: 5 }}>
            <Box
                p={{ xs: 3, sm: 6 }}
                sx={{
                    backgroundColor: 'common.black',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'primary.light'
                }}
            >
                {title && (
                    <Typography
                        sx={{
                            mb: 4,
                            lineHeight: 1.15,
                            color: 'primary.light',
                            fontFamily: 'body1.fontFamily',
                            fontSize: {
                                xs: 'h6.fontSize',
                                md: 'h5.fontSize'
                            }
                        }}
                    >
                        {title}
                    </Typography>
                )}
                {content}
            </Box>
        </DecoratedCorners>
    )
}

const DashboardLayout: React.FC<DashboardLayoutTypes.Sections> = ({ sections }) => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Layout>
            <Box sx={{ display: 'flex' }}>
                {desktop && (
                    <Box
                        mr={5}
                        sx={{
                            width: '18rem',
                            flexShrink: 0,
                            flexGrow: 0
                        }}
                    >
                        <Menu />
                    </Box>
                )}
                <Box sx={{ width: '100%' }}>
                    {sections.map(({ title, content }, index) => (
                        <Section key={index} title={title} content={content} />
                    ))}
                </Box>
            </Box>
        </Layout>
    )
}

export default DashboardLayout
