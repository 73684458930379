import React from 'react'
import { DateTime } from 'luxon'
import { ButtonBase, Box, Container, Typography, useTheme } from '@mui/material'

import divider from 'src/assets/divider-skull.png'
import tertium from 'src/assets/tertium-logo.png'
import pattern from 'src/assets/pattern.png'
import gun from 'src/assets/gun.png'

interface ICurrentDropPromotion {
    date: Date
}

interface ICountdownUnit {
    value: number
    unit: string
    suffix?: string
}

interface Diff {
    days: number
    hours: number
    mins: number
    sec: number
}

function calculateExpiry(date: Date): Diff {
    const exiry = DateTime.fromJSDate(new Date('2023-01-01 00:00:00'))
    const now = DateTime.fromJSDate(date)
    const diff = exiry.diff(now, ['days', 'hours', 'minutes', 'seconds'])

    return {
        days: diff.days ?? 0,
        hours: diff.hours ?? 0,
        mins: diff.minutes ?? 0,
        sec: diff.seconds ?? 0
    }
}

const ThisWeeksDrops: React.FC = () => {
    return (
        <Box sx={{ mb: 6 }}>
            <Typography
                variant="body1"
                sx={{
                    mb: 1,
                    color: 'primary.light',
                    textTransform: 'uppercase',
                    fontFamily: 'body1.fontFamily',
                    fontSize: {
                        xs: '0.85rem',
                        sm: '1.15rem',
                        md: '1.25rem',
                        lg: '1.5rem'
                    }
                }}
            >
                Watch and unlock with
            </Typography>
            <Typography
                variant="h3"
                sx={{
                    lineHeight: 0.9,
                    fontSize: {
                        xs: '2.75rem',
                        sm: '3.5rem',
                        md: '4rem',
                        lg: '4.75rem'
                    }
                }}
            >
                This week&apos;s
            </Typography>
            <Typography
                variant="h2"
                sx={{
                    lineHeight: 0.9,
                    fontSize: {
                        xs: '5.5rem',
                        sm: '7rem',
                        md: '8.25rem',
                        lg: '9.5rem'
                    }
                }}
            >
                Drops
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    fontWeight: 500,
                    lineHeight: 1.15,
                    maxWidth: {
                        xs: '22rem',
                        sm: '18rem',
                        md: '20rem',
                        lg: '24rem'
                    },
                    fontSize: {
                        xs: '0.75rem',
                        md: '0.85rem',
                        lg: '1rem'
                    }
                }}
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
            </Typography>
            <Box sx={{ mt: 6 }}>
                <ButtonBase
                    component="a"
                    href="https://www.twitch.tv/directory/game/Warhammer%3A%20Vermintide%202"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        transition: 'all .5s ease-in-out',
                        border: '1px solid #54ddea',
                        padding: '4px',

                        '&:hover': {
                            background: 'rgba(255, 255, 255, 0.05)',
                            border: '1px solid #ffffff'
                        }
                    }}
                >
                    <Box
                        sx={{
                            pt: 1.5,
                            pb: 1,
                            px: 3,
                            letterSpacing: '0.03em',
                            border: '2px solid #57e8f6',
                            fontFamily: 'h1.fontFamily',
                            fontSize: {
                                xs: '1rem',
                                sm: '1.25rem',
                                md: '1.5rem',
                                lg: '1.75rem'
                            }
                        }}
                    >
                        Find streamer with drops
                    </Box>
                </ButtonBase>
            </Box>
        </Box>
    )
}

const CountdownUnit: React.FC<ICountdownUnit> = ({ value, unit, suffix }) => {
    const theme = useTheme()

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography
                    variant="h3"
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '2.25rem'
                        }
                    }}
                >
                    {parseInt(value.toString()).toString().padStart(2, '0')}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: 'secondary.light',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '0.85rem'
                        }
                    }}
                >
                    {unit}
                </Typography>
            </Box>
            {suffix && (
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: '2.5rem',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '1.75rem'
                        }
                    }}
                >
                    {suffix}
                </Typography>
            )}
        </Box>
    )
}

const CurrentDropPromotion: React.FC<ICurrentDropPromotion> = ({ date }) => {
    const theme = useTheme()
    const { days, hours, mins, sec } = calculateExpiry(date)

    return (
        <Box
            sx={{
                ml: {
                    xs: 0,
                    md: 8
                },
                mb: 6,
                position: 'relative',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `url(${pattern}) center center / cover no-repeat`,
                    opacity: 0.1
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    height: '100%',
                    width: { xs: '85vw', sm: '32rem', md: '25rem', lg: '32rem' },
                    background: `url(${gun}) center center / contain no-repeat`,
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2
                }}
            />
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    position: 'relative',

                    [theme.breakpoints.down('md')]: {
                        minHeight: '26rem'
                    }
                }}
            >
                <Box
                    sx={{
                        mt: 2,
                        ml: { xs: -1, md: -3 },
                        position: 'relative',
                        backgroundColor: 'primary.dark'
                    }}
                >
                    <img
                        src={tertium}
                        alt="Tertium Logo"
                        width={40}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            pointerEvents: 'none',
                            userSelect: 'none'
                        }}
                    />
                    <Box sx={{ pt: 2, pl: 2, pr: 6, pb: 1 }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: 'secondary.light',

                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '0.85rem'
                                }
                            }}
                        >
                            Current drop
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                color: 'common.black',

                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '1.5rem'
                                }
                            }}
                        >
                            Hellfire bolter
                        </Typography>
                    </Box>
                    <Box
                        sx={{ mb: 1, height: 6, width: '100%', backgroundColor: 'secondary.light' }}
                    />
                </Box>
                <Box
                    sx={{
                        ml: 'auto',
                        mb: { xs: -2, md: -3 },
                        mr: { xs: -1, md: -2 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}
                >
                    <Box
                        sx={{
                            px: 3,
                            pt: '0.3em',
                            mb: 1,
                            lineHeight: 1.8,
                            backgroundColor: 'common.black',
                            fontSize: {
                                xs: 'body2.fontSize',
                                sm: 'body1.fontSize'
                            },
                            fontWeight: 'h6.fontWeight',
                            fontFamily: 'h6.fontFamily',
                            textTransform: 'uppercase',
                            display: 'inline-flex'
                        }}
                    >
                        Next drop available in
                    </Box>
                    <Box
                        sx={{
                            pr: 3,
                            pl: 4,
                            pt: 2,
                            pb: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'primary.dark',
                            position: 'relative'
                        }}
                    >
                        <CountdownUnit value={days} unit="days" suffix=":" />
                        <CountdownUnit value={hours} unit="hours" suffix=":" />
                        <CountdownUnit value={mins} unit="min" suffix=":" />
                        <CountdownUnit value={sec} unit="sec" />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -13,
                                left: -3,
                                width: 0,
                                height: 0,
                                borderTop: '20px solid transparent',
                                borderBottom: '20px solid transparent',
                                borderRight: `20px solid ${theme.palette.common.black}`,
                                transform: 'rotate(-45deg)'
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const FeaturedDrop: React.FC = () => {
    const theme = useTheme()
    const [date, setDate] = React.useState<Date>(new Date())

    React.useEffect(() => {
        const update = setInterval(() => {
            setDate(new Date())
        }, 1000)

        return () => {
            clearInterval(update)
        }
    }, [setDate])

    return (
        <Box
            sx={{
                pb: 5,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'secondary.light'
            }}
        >
            <Box sx={{ height: 5, backgroundColor: '#0b0803', mb: 5 }} />
            <img
                src={divider}
                alt="Skull Border Decoration"
                width="100%"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    pointerEvents: 'none',
                    userSelect: 'none',
                    minWidth: 1200
                }}
            />
            <Container sx={{ pt: 5 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',

                        [theme.breakpoints.up('md')]: {
                            flexDirection: 'row'
                        }
                    }}
                >
                    <ThisWeeksDrops />
                    <CurrentDropPromotion date={date} />
                </Box>
            </Container>
        </Box>
    )
}

export default FeaturedDrop
