import React from 'react'

interface ICheckmark {
    size: number
    color: string
}

const Checkmark: React.FC<ICheckmark> = ({ size, color }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="28" cy="28" r="24" stroke={color} strokeWidth={1} />
            <path d="M41.5 20.5L35.5 15L26.5 29L20.5 24L15 30L27.5 41.5L41.5 20.5Z" fill={color} />
        </svg>
    )
}

export default Checkmark
