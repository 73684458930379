import React from 'react'
import { createTwitchURL } from 'src/config'
import { LoadingScreen } from 'src/components'

const TwitchLinking: React.FC = () => {
    React.useEffect(() => {
        window.location.replace(createTwitchURL('linking'))
    }, [])

    return <LoadingScreen />
}

export default TwitchLinking
