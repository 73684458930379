import React from 'react'
import DecoratedCorners from './DecoratedCorners'
import StripeCard from './StripeCard'
import { Link, useLocation } from 'react-router-dom'
import { Box, ButtonBase } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { routes } from 'src/config'
import { Routes } from 'src/types'

import dashboard from 'src/assets/icon-dashboard.png'
import ticket from 'src/assets/icon-ticket.png'
import twitch from 'src/assets/icon-twitch.png'

interface IMenu {
    onClick?: () => void
}

type MenuRoute = {
    text: string
    icon: string
    to: keyof Routes
    endIcon?: IconDefinition
    newTab?: boolean
}

interface IMenuItem extends IMenu {
    route: MenuRoute
    active: boolean
    offset?: boolean
    newTab?: boolean
}

const menuRoutes: Array<MenuRoute> = [
    {
        text: 'Account Dashboard',
        icon: dashboard,
        to: 'dashboard'
    },
    {
        text: 'Redeem a Code',
        icon: ticket,
        to: 'dashboardRedeemCode'
    },
    {
        text: 'Twitch Drops',
        icon: twitch,
        to: 'twitchDrops',
        endIcon: faArrowUpRightFromSquare,
        newTab: true
    }
]

const MenuItem: React.FC<IMenuItem> = ({ route, active, offset, newTab, onClick }) => {
    return (
        <DecoratedCorners
            size={8}
            thickness={2}
            color="primary.light"
            sx={{ mt: offset ? 6 : 0, mb: 2 }}
        >
            <StripeCard
                sx={{
                    '&:hover': {
                        backgroundColor: 'grey.900'
                    }
                }}
            >
                <ButtonBase
                    component={Link}
                    to={routes[route.to]}
                    onClick={onClick}
                    {...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : undefined)}
                    sx={{
                        px: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        lineHeight: 3.5,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: active ? 'primary.light' : 'transparent',
                        fontSize: {
                            xs: 'body2.fontSize',
                            md: 'body1.fontSize'
                        }
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <img src={route.icon} alt={route.text} width={30} />
                    </Box>
                    <Box component="span" sx={{ pl: 3, pb: '3px' }}>
                        {route.text}
                    </Box>
                    {route.endIcon && (
                        <Box sx={{ marginLeft: 'auto' }}>
                            <FontAwesomeIcon icon={route.endIcon} size="lg" />
                        </Box>
                    )}
                </ButtonBase>
            </StripeCard>
        </DecoratedCorners>
    )
}

const Menu: React.FC<IMenu> = ({ onClick }) => {
    const { pathname } = useLocation()

    return (
        <Box component="nav">
            {menuRoutes.map((route, index) => (
                <MenuItem
                    key={index}
                    route={route}
                    active={routes[route.to] === pathname}
                    offset={index === menuRoutes.length - 1}
                    newTab={route.newTab}
                    onClick={onClick}
                />
            ))}
        </Box>
    )
}

export default Menu
