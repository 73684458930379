import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DecoratedCorners } from 'src/components'

import ticket from 'src/assets/icon-ticket.png'
import twitch from 'src/assets/icon-twitch.png'

interface IBenefit {
    img: string
    size: number
    title: string
    content: string
}

const Benefit: React.FC<IBenefit> = ({ img, size, title, content }) => {
    return (
        <Box sx={{ mt: 4, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex' }}>
                <img src={img} alt={title} width={size} />
            </Box>
            <Box sx={{ ml: { xs: 3, sm: 5 } }}>
                <Typography
                    variant="body1"
                    variantMapping={{ body1: 'h4' }}
                    color="common.white"
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: {
                            xs: 'body1.fontSize',
                            sm: 'h6.fontSize',
                            md: 'h5.fontSize'
                        }
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    color="common.white"
                    sx={{
                        maxWidth: '18rem',
                        fontSize: {
                            xs: 'caption.fontSize',
                            sm: 'body2.fontSize'
                        }
                    }}
                >
                    {content}
                </Typography>
            </Box>
        </Box>
    )
}

const AccessBenefits: React.FC = () => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const size = desktop ? 60 : 50

    return (
        <DecoratedCorners size={12} thickness={3} color="primary.light" sx={{ mb: 5 }}>
            <Box
                p={{ xs: 4, sm: 6 }}
                sx={{
                    backgroundColor: 'common.black',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'primary.light'
                }}
            >
                <Typography
                    sx={{
                        mb: 1,
                        lineHeight: 1.15,
                        color: 'primary.light',
                        fontFamily: 'body1.fontFamily',
                        fontSize: {
                            xs: 'h6.fontSize',
                            md: 'h5.fontSize'
                        }
                    }}
                >
                    A Darktide account
                    <br />
                    grants you access to:
                </Typography>
                <Box>
                    <Benefit
                        img={ticket}
                        size={size}
                        title="Redeem codes"
                        content="Add unique items to your account."
                    />
                    <Benefit
                        img={twitch}
                        size={size}
                        title="Twitch drops"
                        content="Link your Twitch account to unlock exclusive content by watching streams."
                    />
                </Box>
            </Box>
        </DecoratedCorners>
    )
}

export default AccessBenefits
