import React from 'react'
import InputField from './InputField'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { putEmail } from 'src/transfer'

const Email: React.FC = () => {
    const ref = React.useRef<boolean>(true)

    const { dispatch } = useSnackbarContext()
    const { account, setAccount } = useAccountContext()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>(account?.email?.address ?? '')

    async function handleSave(): Promise<void> {
        setLoading(true)

        await putEmail({
            email,
            sub: account!.sub,
            accessToken: account!.accessToken,
            mounted: ref.current,
            next: (response) => {
                setLoading(false)

                if (response.error) {
                    setEmail(account?.email?.address ?? '')

                    dispatch({
                        severity: 'error',
                        content: 'Email address not accepted'
                    })
                } else {
                    setAccount({
                        ...account!,
                        email: {
                            ...account!.email,
                            address: email
                        }
                    })

                    dispatch({
                        severity: 'success',
                        content: 'Email saved'
                    })
                }
            }
        })

        setLoading(false)
    }

    return (
        <InputField
            name="email"
            label="Email"
            placeholder="Email"
            validator="isValidEmail"
            helperText={
                account?.email?.address && !account?.email?.verified
                    ? 'Check your inbox for a confirmation email'
                    : undefined
            }
            value={email}
            loading={loading}
            saveDisabled={email === account?.email?.address}
            onReset={() => setEmail(account?.email?.address ?? '')}
            onChange={(value) => setEmail(value)}
            onSave={handleSave}
        />
    )
}

export default Email
