import React from 'react'
import Backdrop from './Backdrop'
import Footer from './Footer'
import Header from './Header'
import { Container } from '@mui/material'

const Layout: React.FC = ({ children }) => {
    return (
        <React.Fragment>
            <Header />
            <Backdrop>
                <Container sx={{ pt: 4, pb: 8, minWidth: '300px' }}>{children}</Container>
            </Backdrop>
            <Footer />
        </React.Fragment>
    )
}

export default Layout
