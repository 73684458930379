import React from 'react'
import { Box, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { CustomButton } from 'src/components'
import { redeemCode } from 'src/transfer'

function sanitizeInput(value: string): string {
    return value.replace(/\s/g, '')
}

const CodeRedemption: React.FC = () => {
    const theme = useTheme()
    const match = useMediaQuery(theme.breakpoints.up('sm'))
    const ref = React.useRef<boolean>(true)

    const { account } = useAccountContext()
    const { dispatch } = useSnackbarContext()

    const [keyId, setKeyId] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)

    const disabled = keyId.length < 3 || loading

    async function redeem(): Promise<void> {
        setLoading(true)

        await redeemCode({
            keyId,
            sub: account!.sub,
            accessToken: account!.accessToken,
            mounted: ref.current,
            next: ({ error, errorCode }) => {
                if (error) {
                    let content =
                        'The code you provided has either expired or already been redeemed'

                    switch (errorCode) {
                        case 409:
                            content =
                                'You have already redeemed this code or received the items granted from this campaign'
                            break
                        case 404:
                            content = 'The code you provided is not valid'
                            break
                        case 500:
                            content = 'Failed to redeem code'
                            break
                    }

                    dispatch({ severity: 'error', content })
                } else {
                    dispatch({
                        severity: 'success',
                        content: 'Code successfully redeemed'
                    })
                }

                setLoading(false)
            }
        })
    }

    const button = (
        <CustomButton
            content="Redeem"
            disabled={disabled}
            loading={loading}
            onClick={disabled ? undefined : () => redeem()}
        />
    )

    return (
        <React.Fragment>
            <Box sx={{ mb: 4 }}>
                <TextField
                    fullWidth
                    id="code-redeem"
                    placeholder="Enter your code here"
                    value={keyId}
                    onChange={(event) => setKeyId(sanitizeInput(event.target.value))}
                    sx={{ mb: 1 }}
                    InputProps={
                        match
                            ? {
                                  endAdornment: (
                                      <InputAdornment position="end">{button}</InputAdornment>
                                  )
                              }
                            : {}
                    }
                />
                {!match && button}
            </Box>
            <Typography variant="caption" variantMapping={{ caption: 'p' }} color="grey.700">
                NOTE: Once redeemed, items can be found in your in-game inventory.
            </Typography>
        </React.Fragment>
    )
}

export default CodeRedemption
