import React from 'react'
import Layout from './Layout'
import WelcomeBanner from './WelcomeBanner'
import { Grid } from '@mui/material'

export namespace ActionLayoutTypes {
    export interface Columns {
        leftColumn: React.ReactNode
        rightColumn: React.ReactNode
    }
}

const ActionLayout: React.FC<ActionLayoutTypes.Columns> = ({ leftColumn, rightColumn }) => {
    return (
        <Layout>
            <WelcomeBanner />
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    {leftColumn}
                </Grid>
                <Grid item xs={12} md={6}>
                    {rightColumn}
                </Grid>
            </Grid>
        </Layout>
    )
}

export default ActionLayout
