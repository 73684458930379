import { ElementType } from 'react'
import { Route, Routes } from 'src/types'
import {
    AuthHandler,
    AccountDashboard,
    Home,
    Login,
    NotFound,
    RedeemCode,
    Register,
    SteamLinking,
    SteamLogin,
    TwitchDrops,
    TwitchLinking,
    XboxLogin,
    XboxLinking
} from 'src/pages'

export const routes: Routes = {
    home: '/',
    login: '/login',
    register: '/register',
    dashboard: '/dashboard',
    dashboardRedeemCode: '/dashboard/redeem-code',
    authHandler: '/linking',
    steamAuthentication: '/auth/steam',
    steamLinking: '/sso/linking/steam',
    steamLogin: '/sso/login/steam',
    twitchDrops: '/twitch-drops',
    twitchLinking: '/sso/linking/twitch',
    xboxLinking: '/sso/linking/windows',
    xboxLogin: '/sso/login/windows',
    notFound: '*'
}

export type RouteComponent = {
    path: Route
    title: string
    restricted: boolean
    component: ElementType
    action?: () => void
}

export const routeComponents: Array<RouteComponent> = [
    {
        path: 'home',
        title: 'Darktide',
        restricted: false,
        component: Home
    },
    {
        path: 'login',
        title: 'Darktide | Login',
        restricted: false,
        component: Login
    },
    {
        path: 'register',
        title: 'Darktide | Register',
        restricted: true,
        component: Register
    },
    {
        path: 'dashboard',
        title: 'Darktide | Account Dashboard',
        restricted: true,
        component: AccountDashboard
    },
    {
        path: 'dashboardRedeemCode',
        title: 'Darktide | Redeem Code',
        restricted: true,
        component: RedeemCode
    },
    {
        path: 'authHandler',
        title: 'Darktide | Login',
        restricted: false,
        component: AuthHandler
    },
    {
        path: 'steamLinking',
        title: 'Darktide | Linking',
        restricted: false,
        component: SteamLinking
    },
    {
        path: 'steamLogin',
        title: 'Darktide | Login',
        restricted: false,
        component: SteamLogin
    },
    {
        path: 'twitchDrops',
        title: 'Darktide | Twitch Drops',
        restricted: false,
        component: TwitchDrops
    },
    {
        path: 'twitchLinking',
        title: 'Darktide | Linking',
        restricted: false,
        component: TwitchLinking
    },
    {
        path: 'xboxLinking',
        title: 'Darktide | Linking',
        restricted: false,
        component: XboxLinking
    },
    {
        path: 'xboxLogin',
        title: 'Darktide | Login',
        restricted: false,
        component: XboxLogin
    },
    {
        path: 'notFound',
        title: 'Darktide',
        restricted: false,
        component: NotFound
    }
]
