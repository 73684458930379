import React from 'react'
import { Link } from 'react-router-dom'
import { Box, ButtonBase, ButtonBaseProps, SxProps } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface ICustomButton {
    content: string
    disabled?: boolean
    loading?: boolean
    fullWidth?: boolean
    onClick?: () => void
    link?: {
        href: string
        external?: boolean
    }
    sx?: SxProps
}

const CustomButton: React.FC<ICustomButton> = ({
    content,
    disabled,
    loading,
    fullWidth,
    onClick,
    link,
    sx
}) => {
    const preventActions = disabled || loading

    const btnProps: ButtonBaseProps<React.ElementType<any>, {}> = preventActions
        ? {}
        : {
              component: link ? (link.external ? 'a' : Link) : 'button',
              to: link && !link.external ? link.href : undefined,
              href: link && link.external ? link.href : undefined,
              onClick: onClick ? () => onClick() : undefined
          }

    return (
        <ButtonBase
            {...btnProps}
            disabled={preventActions}
            sx={{
                fontSize: 'body1.fontSize',
                fontFamily: 'body1.fontFamily',
                fontWeight: 'bold',
                lineHeight: '1.75',
                letterSpacing: '0.02857em',
                textTransform: 'uppercase',
                minWidth: '64px',
                color: preventActions ? 'grey.800' : 'common.black',
                backgroundColor: preventActions ? 'grey.600' : 'primary.light',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: preventActions ? 'grey.500' : 'common.white',
                filter: 'none',
                ...(fullWidth
                    ? {
                          width: '100%',
                          padding: '6px 18px'
                      }
                    : {
                          padding: '6px 42px'
                      }),

                '&:hover': {
                    ...(!preventActions ? { filter: 'brightness(90%)' } : {})
                },
                ...sx
            }}
        >
            {loading ? (
                <Box>
                    <FontAwesomeIcon size="lg" spin icon={faSpinner} />
                </Box>
            ) : (
                <Box sx={{ pb: '3px' }}>{content}</Box>
            )}
        </ButtonBase>
    )
}

export default CustomButton
