import React from 'react'
import InputField from './InputField'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { putAccountName } from 'src/transfer'

const AccountName: React.FC = () => {
    const ref = React.useRef<boolean>(true)

    const { dispatch } = useSnackbarContext()
    const { account, setAccount } = useAccountContext()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>(account?.name ?? '')

    async function handleSave(): Promise<void> {
        setLoading(true)

        await putAccountName({
            name,
            sub: account!.sub,
            accessToken: account!.accessToken,
            mounted: ref.current,
            next: (response) => {
                setLoading(false)

                if (response.error) {
                    setName(account?.name ?? '')

                    dispatch({
                        severity: 'error',
                        content: 'Account name not accepted'
                    })
                } else {
                    setAccount({
                        ...account!,
                        ...response.data,
                        allowRename: false
                    })

                    dispatch({
                        severity: 'success',
                        content: 'Account name saved'
                    })
                }
            }
        })
    }

    return (
        <InputField
            name="accountName"
            value={name}
            label="Fatshark Account Name"
            placeholder="Account Name"
            validator="isValidAccountName"
            loading={loading}
            editDisabled={!account?.allowRename}
            saveDisabled={name === account?.name}
            onChange={(value) => setName(value)}
            onReset={() => setName(account?.name ?? '')}
            onSave={handleSave}
            helperText={`Rename tokens left: [${account?.allowRename ? '1' : '0'}]`}
            endText={account?.discriminator ? `#${account.discriminator}` : undefined}
        />
    )
}

export default AccountName
