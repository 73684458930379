import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'

interface ILoadingScreen {
    title?: string
}

const LoadingScreen: React.FC<ILoadingScreen> = ({ title }) => {
    return (
        <Box
            sx={{
                height: '100vh',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'common.black'
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <CircularProgress size={60} />
                {title && (
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                )}
            </Box>
        </Box>
    )
}

export default LoadingScreen
