import React from 'react'
import {
    CodeRedemption,
    DashboardLayout,
    DashboardLayoutTypes,
    DontHaveACode
} from 'src/components'

const RedeemCode: React.FC = () => {
    const sections: Array<DashboardLayoutTypes.Section> = [
        {
            title: 'Code Redemption',
            content: <CodeRedemption />
        },
        {
            title: "Don't Have A Code?",
            content: <DontHaveACode />
        }
    ]

    return <DashboardLayout sections={sections} />
}

export default RedeemCode
