import React from 'react'
import { createXboxURL } from 'src/config'
import { LoadingScreen } from 'src/components'

const XboxLinking: React.FC = () => {
    React.useEffect(() => {
        window.location.replace(createXboxURL('linking'))
    }, [])

    return <LoadingScreen />
}

export default XboxLinking
