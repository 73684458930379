export type ValidationResult = {
    valid: boolean
    errorText: string
}

type Validator = (value: string) => ValidationResult

export interface Validators {
    isValidAccountName: Validator
    isValidEmail: Validator
}

const isValidAccountName: Validator = (name): ValidationResult => {
    let valid: boolean = true
    let errorText: string = ''

    if (name.length < 3) {
        valid = false
        errorText = 'Must be at least 3 characters'
    } else if (name.length > 18) {
        valid = false
        errorText = "Can't be more than 18 characters"
    }

    return { valid, errorText }
}

const isValidEmail: Validator = (email): ValidationResult => {
    let valid = /(.+)@(.+){1,}\.(.+){1,}/g.test(email)

    return {
        valid,
        errorText: valid ? '' : 'Please provide a valid email address'
    }
}

export const validators: Validators = {
    isValidAccountName,
    isValidEmail
}
