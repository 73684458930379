import React from 'react'
import { Box, Typography } from '@mui/material'
import { CustomButton, Layout } from 'src/components'
import { routes } from 'src/config'

const NotFound: React.FC = () => {
    return (
        <Layout>
            <Box px={2} py={8} sx={{ backgroundColor: 'primary.main' }}>
                <Typography
                    align="center"
                    variant="body1"
                    variantMapping={{ body1: 'h4' }}
                    color="primary.light"
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: {
                            xs: 'body2.fontSize',
                            sm: 'body1.fontSize',
                            md: 'h5.fontSize'
                        }
                    }}
                >
                    Oops...
                </Typography>
                <Typography
                    align="center"
                    variant="h1"
                    color="common.white"
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: {
                            xs: '6.5rem',
                            sm: '9.5rem',
                            md: '12.5rem'
                        }
                    }}
                >
                    404
                </Typography>
                <Typography
                    align="center"
                    variant="h2"
                    color="common.white"
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: {
                            xs: 'h5.fontSize',
                            sm: 'h4.fontSize',
                            md: 'h3.fontSize'
                        }
                    }}
                >
                    Page not found
                </Typography>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <CustomButton content="Okay" link={{ href: routes.home }} />
                </Box>
            </Box>
        </Layout>
    )
}

export default NotFound
