import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { DecoratedCorners } from 'src/components'

import aquilas from 'src/assets/aquilas.png'
import veteran from 'src/assets/veteran.png'

const NewsletterBenefits: React.FC = () => {
    const theme = useTheme()

    return (
        <React.Fragment>
            <DecoratedCorners size={12} thickness={3} color="primary.light" sx={{ mb: 5 }}>
                <Box
                    sx={{
                        pt: 3,
                        pb: 2,
                        backgroundColor: 'common.black',
                        position: 'relative',
                        paddingLeft: '10rem',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'primary.light',
                        [theme.breakpoints.up('sm')]: {
                            paddingLeft: '12rem'
                        }
                    }}
                >
                    <Typography
                        pr={2}
                        mb={{ xs: 2, sm: 3 }}
                        variant="h4"
                        sx={{
                            color: 'common.white',
                            fontSize: {
                                xs: 'h5.fontSize',
                                sm: 'h4.fontSize'
                            }
                        }}
                    >
                        True rejects
                        <br />
                        stay up to date
                    </Typography>
                    <Box
                        sx={{
                            pr: 2,
                            pb: 0.5,
                            paddingLeft: '5rem',
                            marginLeft: '-5rem',
                            backgroundColor: 'primary.light',
                            [theme.breakpoints.up('sm')]: {
                                paddingLeft: '7rem',
                                marginLeft: '-7rem'
                            }
                        }}
                    >
                        <Typography
                            variant="body1"
                            color="common.black"
                            sx={{
                                pt: 1,
                                pb: 1,
                                pr: 2,
                                fontWeight: 500,
                                fontSize: {
                                    xs: 'caption.fontSize',
                                    sm: 'body2.fontSize'
                                }
                            }}
                        >
                            Subscribe to our newsletter!
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '-3rem',
                            bottom: '-0.5rem',
                            width: '12rem',
                            height: '12rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transform: 'rotateY(180deg)',
                            filter: 'brightness(125%) grayscale(50%)',
                            [theme.breakpoints.up('sm')]: {
                                width: '13rem',
                                height: '13rem'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                background: `url(${veteran}) bottom left / contain no-repeat`
                            }}
                        />
                    </Box>
                </Box>
            </DecoratedCorners>
            <DecoratedCorners size={12} thickness={3} color="primary.light" sx={{ mb: 4 }}>
                <Box
                    sx={{
                        py: 3,
                        px: 4,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'primary.light',
                        backgroundColor: 'common.black',
                        position: 'relative',
                        paddingRight: '9rem',
                        [theme.breakpoints.up('sm')]: {
                            paddingRight: '15rem'
                        }
                    }}
                >
                    <Typography
                        mb={{ xs: 1, sm: 2 }}
                        variant="h4"
                        sx={{
                            color: 'common.white',
                            fontSize: {
                                xs: 'h6.fontSize',
                                sm: 'h5.fontSize'
                            }
                        }}
                    >
                        And claim rewards
                    </Typography>
                    <Typography
                        variant="body2"
                        color="common.white"
                        sx={{
                            pr: 2,
                            maxWidth: '20rem',
                            fontSize: {
                                xs: 'caption.fontSize',
                                sm: 'body2.fontSize'
                            }
                        }}
                    >
                        As a subscriber, you will be the first in line for exclusive rewards, such
                        as cosmetics and Aquilas, which you can claim through this website.
                    </Typography>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '-2rem',
                            bottom: 0,
                            width: '10rem',
                            height: '10rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transform: 'rotateY(180deg)',
                            filter: 'brightness(125%) grayscale(50%)',
                            [theme.breakpoints.up('sm')]: {
                                width: '14rem',
                                height: '14rem'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                background: `url(${aquilas}) bottom left / contain no-repeat`,
                                marginBottom: '-2rem'
                            }}
                        />
                    </Box>
                </Box>
            </DecoratedCorners>
        </React.Fragment>
    )
}

export default NewsletterBenefits
