import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAccountContext } from 'src/context'
import { routes } from 'src/config'

const Home: React.FC = () => {
    const { authorized } = useAccountContext()

    return authorized ? <Redirect to={routes.dashboard} /> : <Redirect to={routes.login} />
}

export default Home
