import React from 'react'
import { Box, Container, Typography } from '@mui/material'

import banner from 'src/assets/banner.jpg'
import veteran from 'src/assets/veteran.png'
import tertium from 'src/assets/tertium-logo.png'

const logoSize = {
    xs: '5rem',
    sm: '6rem',
    md: '8rem',
    lg: '10rem'
}

const Hero: React.FC = () => {
    return (
        <Box
            sx={{
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: '#07252c',
                background: `url(${banner}) center left / cover no-repeat`
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    background: `url(${tertium}) center center / cover no-repeat`,
                    height: logoSize,
                    width: logoSize
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    background: `url(${veteran}) bottom center / contain no-repeat`,
                    height: '90%',
                    width: {
                        xs: '60vw',
                        sm: '40vw'
                    }
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingTop: {
                        xs: '5rem',
                        sm: '7rem',
                        md: '9rem',
                        lg: '12rem',
                        xl: '14rem'
                    },
                    paddingBottom: {
                        xs: '2rem',
                        sm: '3rem',
                        md: '4rem',
                        lg: '5rem',
                        xl: '6rem'
                    }
                }}
            >
                <Box>
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 1,
                            color: 'primary.light',
                            textTransform: 'uppercase',
                            fontFamily: 'body1.fontFamily',
                            fontSize: {
                                xs: '0.8rem',
                                sm: '1.25rem',
                                md: '1.75rem',
                                lg: '2.2rem'
                            }
                        }}
                    >
                        Watch and unlock with
                    </Typography>
                    <Typography
                        variant="h1"
                        sx={{
                            lineHeight: 1,
                            fontSize: {
                                xs: '2.5rem',
                                sm: '4rem',
                                md: '5.5rem',
                                lg: '7rem'
                            }
                        }}
                    >
                        Twitch Drops
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 500,
                            lineHeight: 1.15,
                            maxWidth: {
                                xs: '85%',
                                sm: '60%',
                                md: '65%',
                                lg: '60%',
                                xl: '65%'
                            },
                            fontSize: {
                                xs: '0.75rem',
                                sm: '0.85rem',
                                md: '1rem',
                                lg: '1.15rem'
                            }
                        }}
                    >
                        Earn in-game rewards for Darktide by watching eligible Twitch streams! We
                        will periodically run Twitch drop campaigns to make your stay in Tertium a
                        stylish, if deadly, fight for survival. You can also unlock content here
                        through keys obtained through other means, such as our creator program or
                        other promotions.
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Hero
