import React from 'react'
import { Box, SxProps } from '@mui/material'

interface IStripeCard {
    sx?: SxProps
}

const StripeCard: React.FC<IStripeCard> = ({ sx, children }) => {
    return (
        <Box sx={{ position: 'relative', bgcolor: 'background.default', ...sx }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    filter: 'blur(1px)',
                    background:
                        'linear-gradient(90deg, rgba(11,11,11,0) 0%, rgba(11,11,11,0.25) 5%, rgba(11,11,11,0.75) 15%, rgba(11,11,11,0.95) 35%, rgba(11,11,11,0.95) 65%, rgba(11,11,11,0.75) 85%, rgba(11,11,11,0.25) 95%, rgba(11,11,11,0) 100%), repeating-linear-gradient(0deg, transparent, transparent 4px, #74f3ff 4px, #309ea4 5px)'
                    //background: 'linear-gradient(90deg, rgba(11,11,11,0) 0%, rgba(11,11,11,0.25) 5%, rgba(11,11,11,0.85) 15%, rgba(11,11,11,0.95) 50%, rgba(11,11,11,0.85) 85%, rgba(11,11,11,0.25) 95%,rgba(11,11,11,0) 100%), repeating-linear-gradient(0deg, transparent, transparent 4px, #51a2aa 4px, #1f4648 5px)'
                }}
            />
            <Box sx={{ position: 'relative' }}>{children}</Box>
        </Box>
    )
}

export default StripeCard
