import React from 'react'
import Menu from './Menu'
import {
    AppBar,
    Box,
    Container,
    Drawer,
    IconButton,
    Toolbar,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { useAccountContext } from 'src/context'

import darktide from 'src/assets/darktide-logo.png'

const Header: React.FC = () => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const { authorized, signOut } = useAccountContext()

    const [open, setOpen] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (open && desktop) {
            setOpen(false)
        }
    }, [open, desktop])

    const logo = (
        <img
            src={darktide}
            width="100%"
            alt="Darktide Logo"
            style={{
                userSelect: 'none',
                pointerEvents: 'none'
            }}
        />
    )

    const signout = authorized ? (
        <Box>
            <IconButton onClick={() => signOut({})} sx={{ fontSize: 'body1.fontSize' }}>
                <FontAwesomeIcon icon={faSignOut} />
            </IconButton>
        </Box>
    ) : null

    return (
        <React.Fragment>
            <AppBar>
                <Container sx={{ position: 'relative' }}>
                    {desktop ? (
                        <Toolbar disableGutters>
                            <Box pt={2} pb={1} sx={{ width: '100%', maxWidth: '14rem' }}>
                                {logo}
                            </Box>
                            <Box sx={{ marginLeft: 'auto' }}>{signout}</Box>
                        </Toolbar>
                    ) : (
                        <React.Fragment>
                            {authorized ? (
                                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                                    <IconButton onClick={() => setOpen(true)}>
                                        <FontAwesomeIcon size="xs" icon={faBars} />
                                    </IconButton>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '10rem'
                                        }}
                                    >
                                        {!open && logo}
                                    </Box>
                                    <Box>{signout}</Box>
                                </Toolbar>
                            ) : (
                                <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '10rem'
                                        }}
                                    >
                                        {!open && logo}
                                    </Box>
                                </Toolbar>
                            )}
                        </React.Fragment>
                    )}
                </Container>
            </AppBar>
            {!desktop && authorized && (
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: '18rem',
                            backgroundColor: 'common.black',
                            backgroundImage: 'none'
                        }
                    }}
                >
                    <Toolbar sx={{ bgcolor: 'common.black' }}>
                        <Box sx={{ width: '10rem', margin: '0 auto' }}>{logo}</Box>
                    </Toolbar>
                    <Box sx={{ pt: 4, px: 2 }}>
                        <Menu onClick={() => setOpen(false)} />
                    </Box>
                </Drawer>
            )}
        </React.Fragment>
    )
}

export default Header
