import React from 'react'
import { Box } from '@mui/material'
import { Form } from 'src/components'

const AccountDetails: React.FC = () => {
    return (
        <React.Fragment>
            <Box>
                <Form.Email />
            </Box>
            <Box sx={{ mt: 8 }}>
                <Form.AccountName />
            </Box>
        </React.Fragment>
    )
}

export default AccountDetails
