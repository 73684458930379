import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { links, Links } from 'src/config'

const socialLinks: Array<keyof Links> = ['newsletter', 'twitter', 'facebook', 'instagram', 'tiktok']

const DontHaveACode: React.FC = () => {
    return (
        <React.Fragment>
            <Typography mb={4}>
                No worries! Most codes are time limited. Make sure to follow Warhammer 40,000:
                Darktide social media and newsletter to stay up to date on code drops.
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {socialLinks.map((link, index) => (
                    <Box
                        key={links[link].name}
                        sx={{ mr: 2, mb: 1, display: 'flex', alignItems: 'center' }}
                    >
                        <Link underline="hover" color="primary.light" href={links[link].href}>
                            {links[link].name}
                        </Link>
                        {index < socialLinks.length - 1 && (
                            <Box sx={{ ml: 2, color: 'grey.800', fontSize: '0.5rem' }}>
                                <FontAwesomeIcon size="xs" icon={faCircle} />
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        </React.Fragment>
    )
}

export default DontHaveACode
