interface Link {
    name: string
    href: string
}

export interface Links {
    community: Link
    discord: Link
    facebook: Link
    gallery: Link
    home: Link
    instagram: Link
    merch: Link
    news: Link
    newsletter: Link
    privacyPolicy: Link
    tiktok: Link
    twitter: Link
    wishlist: Link
}

export const links: Links = {
    community: {
        name: 'Community',
        href: 'https://www.playdarktide.com/#community'
    },
    discord: {
        name: 'Discord',
        href: 'https://discord.com/invite/suVnM2Dmkz'
    },
    facebook: {
        name: 'Facebook',
        href: 'https://www.facebook.com/PlayDarktide'
    },
    gallery: {
        name: 'Gallery',
        href: 'https://www.playdarktide.com/#media-gallery'
    },
    home: {
        name: 'Home',
        href: 'https://www.playdarktide.com/'
    },
    instagram: {
        name: 'Instagram',
        href: 'https://www.instagram.com/playdarktide/'
    },
    merch: {
        name: 'Merch',
        href: 'https://merch.warhammer.com/darktide'
    },
    news: {
        name: 'News',
        href: 'https://www.playdarktide.com/#news'
    },
    newsletter: {
        name: 'Fatshark Newsletter',
        href: 'https://www.playdarktide.com/#newsletter'
    },
    privacyPolicy: {
        name: 'Privacy Policy',
        href: 'https://www.playdarktide.com/docs/privacy-policy'
    },
    tiktok: {
        name: 'TikTok',
        href: 'https://www.tiktok.com/@fatsharkgames?lang=en'
    },
    twitter: {
        name: 'Twitter',
        href: 'https://twitter.com/Darktide40K'
    },
    wishlist: {
        name: 'Wishlist',
        href: 'https://store.steampowered.com/login/?redir=app/1361210'
    }
}
