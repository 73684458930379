import React from 'react'
import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { postMarketingPreferences } from 'src/transfer'
import { MarketingPreferences } from 'src/types'
import { CustomButton } from 'src/components'
import { links } from 'src/config'

interface IFormCheckbox {
    name: keyof MarketingPreferences
    label: React.ReactNode
    checked: boolean
    onChange: (name: keyof MarketingPreferences, checked: boolean) => void
    content?: React.ReactNode
    list?: {
        subheader: string
        items: Array<string>
    }
}

function initialData(marketingPreferences?: MarketingPreferences): MarketingPreferences {
    return {
        termsAgreed: true,
        newsletterSubscribe: !!marketingPreferences?.newsletterSubscribe,
        optIn: !!marketingPreferences?.optIn
    }
}

const FormCheckbox: React.FC<IFormCheckbox> = ({
    name,
    label,
    checked,
    onChange,
    content,
    list
}) => {
    return (
        <Box sx={{ mt: 3 }}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
                <FormGroup>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{
                            '& .MuiTypography-body1': {
                                fontWeight: 400,
                                lineHeight: 1.25,
                                fontSize: {
                                    xs: 'caption.fontSize',
                                    sm: 'body2.fontSize',
                                    lg: 'body1.fontSize'
                                }
                            },
                            marginLeft: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 1
                        }}
                        control={
                            <Checkbox
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={checked}
                                onChange={() => onChange(name, !checked)}
                            />
                        }
                        label={label}
                    />
                </FormGroup>
                <FormHelperText component="div" sx={{ color: 'grey.400' }}>
                    {content}
                    {list && (
                        <List
                            dense
                            subheader={list.subheader}
                            sx={{
                                mt: content ? 2 : 0,
                                color: 'inherit',
                                '& .MuiTypography-root': { fontSize: 'inherit' }
                            }}
                        >
                            {list.items.map((item, index) => (
                                <ListItem key={`${name}-list-item-${index}`} sx={{ py: 0, pl: 1 }}>
                                    <ListItemIcon
                                        sx={{
                                            mr: 1,
                                            color: 'grey.500',
                                            minWidth: 0,
                                            fontSize: '0.5rem'
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faCircle} size="xs" />
                                    </ListItemIcon>
                                    <ListItemText>{item}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </FormHelperText>
            </FormControl>
            <Divider sx={{ mt: 3, borderColor: 'grey.800' }} />
        </Box>
    )
}

const UserPreferences: React.FC = () => {
    const ref = React.useRef<boolean>(true)

    const { dispatch } = useSnackbarContext()
    const { account, setAccount } = useAccountContext()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<MarketingPreferences>(
        initialData(account?.marketingPreferences)
    )

    const privacyPolicyLink = (
        <Link underline="hover" color="primary.light" href={links.privacyPolicy.href}>
            Privacy Policy
        </Link>
    )

    async function handleSubmit(): Promise<void> {
        setLoading(true)

        await postMarketingPreferences({
            data,
            sub: account!.sub,
            accessToken: account!.accessToken,
            mounted: ref.current,
            next: (response) => {
                setLoading(false)

                if (response.error) {
                    setData(initialData(account?.marketingPreferences))

                    dispatch({
                        severity: 'error',
                        content: 'Failed to update preferences'
                    })
                } else {
                    setAccount({ ...account!, marketingPreferences: data })

                    dispatch({
                        severity: 'success',
                        content: 'Preferences saved'
                    })
                }
            }
        })
    }

    function handleChange(name: keyof MarketingPreferences, checked: boolean): void {
        setData({ ...data, [name]: checked })
    }

    return (
        <Box>
            <FormCheckbox
                name="newsletterSubscribe"
                checked={data.newsletterSubscribe}
                onChange={handleChange}
                label="Subscribe to the Fatshark Newsletter"
                content="Our newsletter will inform you about upcoming content, DLC releases, and deals relevant to you."
            />
            <FormCheckbox
                name="optIn"
                checked={data.optIn}
                onChange={handleChange}
                label="Get personalised email updates"
                content={
                    <React.Fragment>
                        Sign up to receive personalised news and updates from Fatshark. This can
                        include special offers, unique rewards, beta invites and more. All
                        communication is sent in accordance with our {privacyPolicyLink}.
                    </React.Fragment>
                }
            />
            <Box sx={{ mt: 3 }}>
                <Typography
                    variant="caption"
                    variantMapping={{ caption: 'p' }}
                    color="grey.500"
                    mb={3}
                >
                    NOTE: Your selection here does not impact your ability to get Fatshark account
                    emails. You can unsubscribe to our emails at any time, by clicking the
                    unsubscribe link in the newsletters.
                </Typography>
                <CustomButton
                    content="Update preferences"
                    loading={loading}
                    disabled={
                        data.newsletterSubscribe ===
                            account!.marketingPreferences.newsletterSubscribe &&
                        data.optIn === account!.marketingPreferences.optIn
                    }
                    onClick={handleSubmit}
                />
            </Box>
        </Box>
    )
}

export default UserPreferences
