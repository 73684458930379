import React from 'react'
import {
    AccountDetails,
    DashboardLayout,
    DashboardLayoutTypes,
    UserPreferences
} from 'src/components'

const AccountDashboard: React.FC = () => {
    const sections: Array<DashboardLayoutTypes.Section> = [
        {
            title: 'Account Details',
            content: <AccountDetails />
        },
        {
            title: 'User Preferences',
            content: <UserPreferences />
        }
    ]

    return <DashboardLayout sections={sections} />
}

export default AccountDashboard
