import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faSteam, faTwitch, faWindows } from '@fortawesome/free-brands-svg-icons'
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { clearLinkingData, getLinkingData } from 'src/utils'
import { Checkmark, DecoratedCorners, FootNote, StripeCard } from 'src/components'
import { getUser, putAccountLinking, unlinkPlatformAccount } from 'src/transfer'
import { routes } from 'src/config'

import left from 'src/assets/card-border-left.png'
import right from 'src/assets/card-border-right.png'

interface IActionButton {
    icon: IconDefinition
    disabled: boolean
    background: string
    content: string
    onClick?: () => void
    href?: string
}

interface IStep {
    step: number
    title: string
    completed: boolean
    buttons: Array<IActionButton>
    info?: string
}

const highlightStyles: React.CSSProperties = {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '1px',
    background:
        'linear-gradient(90deg, rgba(57,147,155,0) 0%, rgba(57,147,155,1) 25%, rgba(57,147,155,1) 75%, rgba(57,147,155,0) 100%)'
}

const Darktide: React.FC = () => (
    <Typography component="span" color="inherit" variant="inherit" sx={{ fontWeight: 600 }}>
        Warhammer 40,000: Darktide
    </Typography>
)

const ActionButton: React.FC<IActionButton> = ({
    icon,
    disabled,
    background,
    content,
    onClick,
    href
}) => {
    const theme = useTheme()

    let btnProps: any = {
        disabled,
        size: 'medium',
        variant: 'contained',
        fullWidth: true
    }

    if (!disabled && onClick) {
        btnProps.onClick = () => onClick()
    }

    if (!disabled && href) {
        btnProps.component = Link
        btnProps.to = href
    }

    return (
        <DecoratedCorners
            size={8}
            thickness={2}
            color={disabled ? theme.palette.action.disabled : theme.palette.common.white}
            sx={{ mb: 1 }}
        >
            <Button
                {...btnProps}
                startIcon={<FontAwesomeIcon size="1x" icon={icon} />}
                sx={{
                    color: 'common.white',
                    ...(!disabled && { background }),
                    '&:hover': {
                        ...(!disabled && { filter: 'brightness(85%)' })
                    }
                }}
            >
                <Box sx={{ pl: 0.5, pb: '3px' }}>{content}</Box>
            </Button>
        </DecoratedCorners>
    )
}

const Step: React.FC<IStep> = ({ step, title, completed, buttons, info }) => {
    const theme = useTheme()

    const borderStyles: React.CSSProperties = {
        position: 'absolute',
        top: -2,
        height: '100%',
        width: 31
    }

    return (
        <StripeCard sx={{ my: 1, mx: 'auto', minWidth: '230px', maxWidth: '400px' }}>
            <Box
                sx={{
                    position: 'relative',
                    height: '500px',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box
                    sx={{
                        ...borderStyles,
                        left: -12,
                        background: `url(${left}) top center / contain no-repeat`
                    }}
                />
                <Box
                    sx={{
                        ...borderStyles,
                        right: -12,
                        background: `url(${right}) top center / contain no-repeat`
                    }}
                />
                <Box
                    sx={{
                        mt: 5,
                        mb: 3,
                        pt: 3,
                        pb: 1,
                        background:
                            'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 100%)',
                        position: 'relative'
                    }}
                >
                    <Box sx={{ ...highlightStyles, top: -1 }} />
                    <Box sx={{ ...highlightStyles, bottom: -1 }} />
                    <Typography align="center" variant="h3">
                        Step
                    </Typography>
                    <Typography align="center" variant="h1">
                        0{step}
                    </Typography>
                </Box>
                <Typography
                    align="center"
                    variant="body2"
                    sx={{
                        px: 3,
                        fontWeight: 700,
                        color: 'primary.light'
                    }}
                >
                    {title}
                </Typography>
                <Box sx={{ p: 3, pb: 0 }}>
                    {buttons.map((btn, index) => (
                        <ActionButton key={`step-${step}-btn-${index}`} {...btn} />
                    ))}
                </Box>
                {info ? (
                    <Typography
                        align="center"
                        variant="caption"
                        sx={{
                            px: 3,
                            pt: 1,
                            fontWeight: 400,
                            color: 'primary.light'
                        }}
                    >
                        {info}
                    </Typography>
                ) : null}
                {completed && (
                    <Box
                        sx={{
                            mt: 'auto',
                            mb: 2,
                            mx: 'auto'
                        }}
                    >
                        <Checkmark size={80} color={theme.palette.success.main} />
                    </Box>
                )}
            </Box>
        </StripeCard>
    )
}

const LinkingSteps: React.FC = () => {
    const { dispatch } = useSnackbarContext()
    const { account, authorized, setAccount } = useAccountContext()

    const [loading, setLoading] = React.useState<boolean>(false)

    const twitchLinkingData = getLinkingData()

    const linkingCompleted = React.useMemo((): boolean => {
        return 'twitch' in (account?.linkedAccounts ?? {})
    }, [account])

    const platformCompleted = React.useMemo((): boolean => {
        return authorized || linkingCompleted
    }, [authorized, linkingCompleted])

    const twitchCompleted = React.useMemo((): boolean => {
        if (linkingCompleted) {
            return true
        } else if (!twitchLinkingData?.LinkingToken || !twitchLinkingData?.Sub) {
            return false
        } else {
            return twitchLinkingData.Sub === account?.sub
        }
    }, [account, linkingCompleted, twitchLinkingData])

    const ref = React.useRef<boolean>(true)

    async function unlinkAccounts(): Promise<void> {
        setLoading(true)

        await unlinkPlatformAccount({
            sub: account!.sub,
            accessToken: account!.accessToken,
            platform: 'twitch',
            platformId: account!.linkedAccounts?.twitch ?? '',
            mounted: ref.current,
            next: async ({ error }) => {
                if (error) {
                    dispatch({
                        severity: 'error',
                        content: 'Failed to unlink accounts'
                    })
                } else {
                    await getUser({
                        sub: account!.sub,
                        accessToken: account!.accessToken,
                        mounted: ref.current,
                        next: (response) => {
                            if (response.error || !response.data) {
                                dispatch({
                                    severity: 'error',
                                    content: 'Failed to update account'
                                })
                            } else {
                                dispatch({
                                    severity: 'success',
                                    content: 'Twitch account successfully unlinked'
                                })

                                setAccount(response.data)
                            }
                        }
                    })
                }

                setLoading(false)
            }
        })
    }

    async function linkAccounts(): Promise<void> {
        setLoading(true)

        await putAccountLinking({
            sub: account!.sub,
            accessToken: account!.accessToken,
            linkingToken: twitchLinkingData?.LinkingToken ?? '',
            mounted: ref.current,
            next: async ({ error }) => {
                if (error) {
                    dispatch({
                        severity: 'error',
                        content: 'Failed to link accounts'
                    })
                } else {
                    await getUser({
                        sub: account!.sub,
                        accessToken: account!.accessToken,
                        mounted: ref.current,
                        next: (response) => {
                            if (response.error || !response.data) {
                                dispatch({
                                    severity: 'error',
                                    content: 'Failed to update account'
                                })
                            } else {
                                dispatch({
                                    severity: 'success',
                                    content: 'Accounts successfully linked'
                                })

                                setAccount(response.data)
                            }
                        }
                    })
                }

                clearLinkingData()
                setLoading(false)
            }
        })
    }

    return (
        <Box
            sx={{
                py: {
                    xs: '2rem',
                    md: '3rem',
                    lg: '4rem'
                }
            }}
        >
            <Container>
                <Typography
                    mb={3}
                    align="center"
                    variant="body1"
                    sx={{
                        color: 'primary.light',
                        textTransform: 'uppercase',
                        fontFamily: 'body1.fontFamily',
                        fontSize: {
                            xs: '1rem',
                            sm: '1.25rem',
                            md: '1.75rem',
                            lg: '2.2rem'
                        }
                    }}
                >
                    How to receive rewards
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Step
                            step={1}
                            completed={platformCompleted}
                            title="Sign in with your game platform"
                            buttons={[
                                {
                                    icon: faSteam,
                                    disabled: platformCompleted || loading,
                                    background:
                                        'linear-gradient(125deg, rgb(70, 186, 223) 0%, rgb(57, 162, 212) 100%)',
                                    content: 'Sign in',
                                    href: routes.steamLinking
                                },
                                {
                                    icon: faWindows,
                                    disabled: platformCompleted || loading,
                                    background:
                                        'linear-gradient(125deg, rgb(158, 211, 149) 0%, rgb(101, 184, 87) 100%)',
                                    content: 'Sign in',
                                    href: routes.xboxLinking
                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Step
                            step={2}
                            completed={twitchCompleted}
                            title="Sign in with your Twitch account"
                            buttons={[
                                {
                                    icon: faTwitch,
                                    disabled: !platformCompleted || twitchCompleted || loading,
                                    background:
                                        'linear-gradient(125deg, rgb(200, 105, 255) 0%, rgb(160, 80, 255) 100%)',
                                    content: 'Sign in',
                                    href: routes.twitchLinking
                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {linkingCompleted ? (
                            <Step
                                step={3}
                                completed
                                title="Link accounts to activate Drops"
                                info="Your accounts are now linked. Use this action to unlink your Twitch account."
                                buttons={[
                                    {
                                        icon: faLinkSlash,
                                        disabled: loading,
                                        background:
                                            'linear-gradient(125deg, rgb(249, 105, 105) 0%, rgb(255, 80, 80) 100%)',
                                        content: 'Unlink accounts',
                                        onClick: unlinkAccounts
                                    }
                                ]}
                            />
                        ) : (
                            <Step
                                step={3}
                                completed={linkingCompleted}
                                title="Link accounts to activate Drops"
                                buttons={[
                                    {
                                        icon: faLink,
                                        disabled:
                                            !platformCompleted ||
                                            !twitchCompleted ||
                                            linkingCompleted ||
                                            loading,
                                        background:
                                            'linear-gradient(125deg, rgb(105, 168, 249) 0%, rgb(135, 80, 255) 100%)',
                                        content: 'Link accounts',
                                        onClick: linkAccounts
                                    }
                                ]}
                            />
                        )}
                    </Grid>
                </Grid>
                <FootNote centered>
                    To receive DROPS, you must have played <Darktide /> after adding it to your
                    library.
                </FootNote>
            </Container>
        </Box>
    )
}

export default LinkingSteps
