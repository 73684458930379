import React from 'react'
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Link,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { validators, ValidationResult } from 'src/utils'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { MarketingPreferences } from 'src/types'
import { postRegistration } from 'src/transfer'
import { CustomButton } from 'src/components'
import { links } from 'src/config'

interface IFormCheckbox {
    name: keyof MarketingPreferences
    label: React.ReactNode
    checked: boolean
    onChange: (name: keyof MarketingPreferences, checked: boolean) => void
    disabled?: boolean
}

interface IInfoText {
    content: React.ReactNode
}

const initialPreferences: MarketingPreferences = {
    termsAgreed: true,
    newsletterSubscribe: false,
    optIn: false
}

const InfoText: React.FC<IInfoText> = ({ content }) => {
    return (
        <Typography
            component="div"
            variant="body2"
            color="grey.300"
            sx={{
                fontSize: {
                    xs: 'caption.fontSize',
                    sm: 'body2.fontSize'
                }
            }}
        >
            {content}
        </Typography>
    )
}

const FormCheckbox: React.FC<IFormCheckbox> = ({ name, label, checked, onChange, disabled }) => {
    return (
        <FormControlLabel
            label={label}
            disabled={disabled}
            control={
                <Checkbox
                    disabled={disabled}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={checked}
                    onChange={disabled ? undefined : () => onChange(name, !checked)}
                />
            }
            sx={{
                ml: 1,
                '& .MuiTypography-body1': {
                    fontWeight: 400,
                    lineHeight: 1.25,
                    fontSize: {
                        xs: 'caption.fontSize',
                        sm: 'body2.fontSize',
                        lg: 'body1.fontSize'
                    }
                }
            }}
        />
    )
}

const NewsletterRegistration: React.FC = () => {
    const ref = React.useRef<boolean>(true)

    const { dispatch } = useSnackbarContext()
    const { account, setAccount } = useAccountContext()

    const [email, setEmail] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [preferences, setPreferences] = React.useState<MarketingPreferences>(initialPreferences)

    const { valid, errorText } = React.useMemo((): ValidationResult => {
        return !email ? { valid: true, errorText: '' } : validators.isValidEmail(email)
    }, [email])

    const privacyPolicyLink = (
        <Link underline="hover" color="primary.light" href={links.privacyPolicy.href}>
            Privacy Policy
        </Link>
    )

    function handleSubmit(action: 'signup' | 'skip'): void {
        setLoading(true)

        postRegistration({
            sub: account!.sub,
            accessToken: account!.accessToken,
            data:
                action === 'skip'
                    ? { email: undefined, ...initialPreferences }
                    : { email, ...preferences, termsAgreed: true },
            mounted: ref.current,
            next: (response) => {
                setLoading(false)

                if (response.error || !response.data) {
                    dispatch({
                        severity: 'error',
                        content: 'Failed to register account'
                    })
                } else {
                    setAccount({ ...account, ...response.data })

                    dispatch({
                        severity: 'success',
                        content: 'Welcome to your Fatshark Account'
                    })
                }
            }
        })
    }

    function handleChange(name: keyof MarketingPreferences, checked: boolean): void {
        setPreferences({ ...preferences, [name]: checked })
    }

    return (
        <Box sx={{ mb: 5, pl: { xs: 0, md: 4 } }}>
            <FormGroup sx={{ gap: 2 }}>
                <TextField
                    required
                    id="email"
                    label="Enter email to sign up"
                    variant="outlined"
                    value={email}
                    error={!valid}
                    onChange={(event) => setEmail(event.target.value)}
                    helperText={errorText}
                    sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'common.black'
                        },
                        '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                            color: 'common.white'
                        },
                        '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                            {
                                borderColor: 'common.white'
                            }
                    }}
                />
                <Box
                    sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap', gap: 1.5 }}
                >
                    <InfoText content="*" />
                    <InfoText
                        content={
                            <React.Fragment>
                                After signing up you will receive a confirmation email to complete
                                the process. All emails are sent in accordance with our{' '}
                                {privacyPolicyLink}.
                            </React.Fragment>
                        }
                    />
                </Box>
                <FormCheckbox
                    name="newsletterSubscribe"
                    checked={preferences.newsletterSubscribe}
                    onChange={handleChange}
                    label="Subscribe to the Fatshark Newsletter"
                />
                <FormCheckbox
                    name="optIn"
                    checked={preferences.optIn}
                    onChange={handleChange}
                    label="Get personalised email updates"
                />
            </FormGroup>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 4, md: 2 }}
                sx={{ mt: 6 }}
            >
                <CustomButton
                    fullWidth
                    loading={loading}
                    content="Skip"
                    onClick={() => handleSubmit('skip')}
                />
                <CustomButton
                    fullWidth
                    loading={loading}
                    disabled={!email || !valid}
                    content="Sign up"
                    onClick={() => handleSubmit('signup')}
                />
            </Stack>
        </Box>
    )
}

export default NewsletterRegistration
