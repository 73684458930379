import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Link,
    Typography,
    useTheme
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FootNote } from 'src/components'

type FaqEntry = {
    q: string
    a: React.ReactNode
}

const faqs: Array<FaqEntry> = [
    {
        q: 'What are Twitch Drops?',
        a: (
            <React.Fragment>
                Twitch Drops are rewards viewers can receive and unlock through watching content.
                For Darktide specifically, the rewards may vary depending on watch time and
                availability.
            </React.Fragment>
        )
    },
    {
        q: 'What streams do I need to watch to receive rewards?',
        a: (
            <React.Fragment>
                You receive Drops by watching a stream in the Darktide category that states "Drops
                enabled." For Emote Drops, you can only obtain them from certain streamers. Whether
                Drops are enabled or not is always visible at the top of the channel's chat.
                <br />
                <br />
                You can check out all of the active campaigns and how to get rewards via{' '}
                <Link href="https://www.twitch.tv/DROPS/campaigns">this link</Link>.
            </React.Fragment>
        )
    },
    {
        q: 'How do I unlock my rewards?',
        a: (
            <React.Fragment>
                All you have to do is to link your Twitch account with a Fatshark account. Once
                you've done that you'll have to watch a selected stream. The longer you watch, the
                more rewards you'll get.
            </React.Fragment>
        )
    },
    {
        q: "How will I know if I've watched a stream long enough?",
        a: (
            <React.Fragment>
                A pop-up notification will let you know once you’ve unlocked a reward by watching a
                streamer for long enough. Alternatively, you can also check your{' '}
                <Link href="https://www.twitch.tv/DROPS/inventory">Twitch Drops Inventory</Link>.
            </React.Fragment>
        )
    },
    {
        q: 'How do I link my Twitch Account to my Fatshark Account?',
        a: (
            <React.Fragment>
                The steps required to link your accounts are detailed further up on this page. All
                you need to do is to sign in with your platform account, which is either Steam or
                Windows PC, and then sign in with your Twitch account. After that, your accounts are
                ready to be linked.
            </React.Fragment>
        )
    },
    {
        q: 'What kind of rewards can I earn?',
        a: (
            <React.Fragment>
                Most of our rewards will be cosmetics, or in-game currency (Aquilas) players can use
                to unlock cosmetic content in our in-game store.
            </React.Fragment>
        )
    },
    {
        q: 'Are rewards unlimited?',
        a: <React.Fragment>No. Sorry. You can receive each reward once.</React.Fragment>
    },
    {
        q: 'Do I need to have the game open while watching?',
        a: (
            <React.Fragment>
                No. Just enjoy the show, and reap your rewards next time you play the game.
            </React.Fragment>
        )
    },
    {
        q: "I'm a creator and would like to leverage Drops for my audience - How can I do that?",
        a: (
            <React.Fragment>
                <Link href="https://creator.fatshark.se/">Join our creator program!</Link> We
                welcome all Rejects with open arms. The Emperor needs you.
            </React.Fragment>
        )
    },
    {
        q: 'I have multiple accounts. What do I do?',
        a: (
            <React.Fragment>
                If you have multiple accounts, make sure the correct ones are linked so you can
                enjoy your rewards in-game. We will not be able to offer assistance if the correct
                accounts aren’t linked.
            </React.Fragment>
        )
    },
    {
        q: 'Do Drops expire?',
        a: (
            <React.Fragment>
                Yes! Twitch Drops Campaigns can expire. Therefore, please make sure to unlock your
                rewards as soon as possible. Once expired, we will not be able to provide the
                in-game rewards.
            </React.Fragment>
        )
    }
]

const FAQ: React.FC = () => {
    const theme = useTheme()

    return (
        <Box sx={{ py: 8 }}>
            <Container>
                <Typography
                    variant="h2"
                    align="center"
                    sx={{
                        mb: 3,
                        fontSize: {
                            xs: '2.75rem',
                            sm: '3.5rem',
                            md: '4rem',
                            lg: '4.75rem'
                        }
                    }}
                >
                    FAQ
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion key={`faq-${index}`} sx={{ background: 'transparent' }}>
                        <AccordionSummary
                            sx={{ px: { xs: 2, md: 3 }, py: 1 }}
                            expandIcon={
                                <FontAwesomeIcon
                                    color={theme.palette.primary.light}
                                    icon={faCaretDown}
                                />
                            }
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <Typography
                                sx={{
                                    color: 'primary.light',
                                    fontWeight: 400,
                                    width: '85%',
                                    fontSize: { xs: '0.85rem', md: '1rem' }
                                }}
                            >
                                {faq.q}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ p: { xs: 2, md: 3 }, backgroundColor: 'secondary.dark' }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: '0.75rem', md: '0.85rem' },
                                    fontWeight: 400,
                                    lineHeight: 1.25
                                }}
                            >
                                {faq.a}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Box sx={{ mt: 3, pl: { xs: 2, md: 3 } }}>
                    <FootNote>Information about DROPS can be found on Twitch.</FootNote>
                    <FootNote>
                        If the expiration date has passed and you have problems receiving your
                        reward, it will be hard for us to help you so we kindly request that you
                        follow the recommendations given above for the smoothest Twitch DROPS
                        experience.
                    </FootNote>
                </Box>
            </Container>
        </Box>
    )
}

export default FAQ
