import React from 'react'
import { Box, Typography } from '@mui/material'
import { DecoratedCorners, StripeCard } from 'src/components'

import logoFatshark from 'src/assets/logo-fatshark.png'

const WelcomeBanner: React.FC = () => {
    return (
        <DecoratedCorners size={8} thickness={2} color="primary.light" sx={{ mb: 4 }}>
            <StripeCard>
                <Box sx={{ display: 'flex', flexWrap: 'no-wrap' }}>
                    <Box
                        p={4}
                        sx={{
                            width: {
                                xs: '100%',
                                md: '60%'
                            },
                            flexGrow: 0,
                            flexShrink: 0
                        }}
                    >
                        <Typography
                            variant="body1"
                            variantMapping={{ body1: 'h4' }}
                            color="primary.light"
                            sx={{
                                fontSize: {
                                    xs: 'body1.fontSize',
                                    md: 'h5.fontSize'
                                }
                            }}
                        >
                            Welcome to your
                        </Typography>
                        <Typography
                            variant="h2"
                            color="common.white"
                            sx={{
                                textTransform: 'uppercase',
                                fontSize: {
                                    xs: 'h3.fontSize',
                                    md: 'h2.fontSize'
                                }
                            }}
                        >
                            Darktide account
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '40%',
                            flexGrow: 0,
                            flexShrink: 0,
                            position: 'relative',
                            overflow: 'hidden',
                            display: { xs: 'none', md: 'initial' },
                            mb: 0.5
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -2,
                                left: '50%',
                                transform: 'translate(-50%, 45%)',
                                width: { xs: '80%', sm: '100%', md: '150%' },
                                height: { xs: '80%', sm: '100%', md: '150%' },
                                background: `url(${logoFatshark}) center center / contain no-repeat`
                            }}
                        />
                    </Box>
                </Box>
            </StripeCard>
        </DecoratedCorners>
    )
}

export default WelcomeBanner
