import React from 'react'
import { Box, Snackbar as MuiSnackbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { faCircleCheck, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSnackbarContext } from 'src/context'

import grunge from 'src/assets/grunge.jpg'

const Snackbar: React.FC = () => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('md'))
    const { open, message, dismiss, cleanup } = useSnackbarContext()

    function handleClose(event: React.SyntheticEvent | Event, reason?: string): void {
        if (reason === 'clickaway' || reason === 'escapeKeyDown') return
        if (event) event.preventDefault()

        dismiss()
    }

    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={10000}
            onClick={handleClose}
            onClose={handleClose}
            TransitionProps={{ onExited: cleanup }}
            message={
                message ? (
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                        <Box
                            sx={{
                                color:
                                    message?.severity === 'success' ? 'success.main' : 'error.main'
                            }}
                        >
                            <FontAwesomeIcon
                                size="lg"
                                icon={
                                    message?.severity === 'success'
                                        ? faCircleCheck
                                        : faTriangleExclamation
                                }
                            />
                        </Box>
                        <Typography variant="body2">{message.content}</Typography>
                    </Box>
                ) : undefined
            }
            action={
                message ? (
                    <Box sx={{ cursor: 'pointer', color: 'grey.700', p: 1 }} onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Box>
                ) : undefined
            }
            sx={{
                ...(desktop ? { maxWidth: '28rem' } : undefined),
                '& .MuiPaper-root': {
                    backgroundImage: `url(${grunge})`,
                    backgroundPosition: '50% 50%',
                    backgroundSize: '512px 512px'
                }
            }}
        />
    )
}

export default Snackbar
