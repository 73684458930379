import React from 'react'
import { capitalize } from 'lodash'
import { DateTime } from 'luxon'
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faClipboard,
    faClipboardCheck,
    faClipboardQuestion
} from '@fortawesome/free-solid-svg-icons'
import { characterArchetypeIcon, characterSpecializationName, useCopyToClipboard } from 'src/utils'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { CharacterData, CharacterExportResponse } from 'src/types'
import { postCharacterExport } from 'src/transfer'

interface ICharacterCard {
    accountId: string
    character: CharacterData
}

interface ITokenExpiryTimer {
    exportResult: CharacterExportResponse
    onExpiry: () => void
}

const TokenExpiryTimer: React.FC<ITokenExpiryTimer> = ({ exportResult, onExpiry }) => {
    const [expiryTimer, setExpiryTimer] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        const handle = setInterval(() => {
            if (exportResult?.expiry) {
                const expiryTimestamp = Number.parseInt(exportResult.expiry)
                if (expiryTimestamp < new Date().getTime()) {
                    onExpiry()
                } else {
                    setExpiryTimer(
                        DateTime.fromJSDate(new Date(expiryTimestamp))
                            .diff(DateTime.fromJSDate(new Date()))
                            .toFormat('mm:ss')
                    )
                }
            }
        }, 1000)

        return () => {
            clearInterval(handle)
        }
    }, [exportResult, onExpiry])

    return exportResult?.token ? (
        <Typography
            component="div"
            variant="caption"
            sx={{
                mt: 1,
                pl: 1,
                color: 'grey.500',
                fontSize: {
                    xs: 'caption.fontSize',
                    md: 'body2.fontSize'
                }
            }}
        >
            Token expires in {expiryTimer}
        </Typography>
    ) : null
}

const CharacterCard: React.FC<ICharacterCard> = ({ accountId, character }) => {
    const ref = React.useRef<boolean>(true)

    const { account } = useAccountContext()
    const { dispatch } = useSnackbarContext()

    const [copyState, copyText, copyClear] = useCopyToClipboard()

    const [exportResult, setExportResult] = React.useState<CharacterExportResponse>(undefined)
    const [creatingExport, setCreatingExport] = React.useState<boolean>(false)

    React.useEffect(() => {
        let timeout: NodeJS.Timeout | null = null

        if (copyState.message) {
            timeout = setTimeout(() => {
                copyClear()
            }, 5000)
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [copyState, copyClear])

    async function exportCharacter(): Promise<void> {
        setCreatingExport(true)

        await postCharacterExport({
            sub: account!.sub,
            accessToken: account!.accessToken,
            characterId: character.id,
            mounted: ref.current,
            next: (response) => {
                setCreatingExport(false)

                if (response.error || !response.data) {
                    dispatch({
                        severity: 'error',
                        content: 'Failed to export character.'
                    })
                } else {
                    setExportResult(response.data)
                }
            }
        })
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Stack direction="row" spacing={{ xs: 2, sm: 4 }}>
                <Box
                    sx={{
                        p: {
                            xs: 1.5,
                            sm: 2
                        },
                        mt: 1,
                        width: {
                            xs: '75px',
                            sm: '90px'
                        },
                        height: {
                            xs: '75px',
                            sm: '90px'
                        },
                        borderRadius: '50%',
                        bgcolor: 'secondary.light',
                        flexGrow: 0,
                        flexShrink: 0
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            background: `url(${characterArchetypeIcon(
                                character
                            )}) center center / contain no-repeat`
                        }}
                    />
                </Box>
                <Box sx={{ overflow: 'auto' }}>
                    <Tooltip title={character.name ?? 'Character name'} placement="top-start">
                        <Typography
                            noWrap
                            variant="h5"
                            sx={{
                                lineHeight: 1.5,
                                textTransform: 'none',
                                fontFamily: 'body1.fontFamily',
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: 'h6.fontSize',
                                    sm: 'h5.fontSize'
                                }
                            }}
                        >
                            {character.name}
                        </Typography>
                    </Tooltip>
                    <Typography
                        sx={{
                            color: 'grey.300',
                            fontWeight: 500,
                            fontSize: {
                                xs: 'body2.fontSize',
                                sm: 'body1.fontSize'
                            }
                        }}
                    >
                        {(character.archetype ?? '').toUpperCase()} {capitalize(character.gender)}
                    </Typography>
                    <Typography
                        sx={{
                            color: 'grey.300',
                            fontWeight: 500,
                            fontSize: {
                                xs: 'caption.fontSize',
                                sm: 'body2.fontSize'
                            }
                        }}
                    >
                        {characterSpecializationName(character)}
                    </Typography>
                    <Typography
                        sx={{
                            color: 'primary.light',
                            fontWeight: 400,
                            fontSize: {
                                xs: 'caption.fontSize',
                                sm: 'body2.fontSize'
                            }
                        }}
                    >
                        Level {character.level}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        {exportResult?.token ? (
                            <React.Fragment>
                                <Stack direction="row" spacing={1}>
                                    <Tooltip
                                        open={Boolean(copyState.message)}
                                        title={copyState.message ?? ''}
                                        placement="top-end"
                                    >
                                        <Box
                                            sx={{
                                                p: 1,
                                                color: 'primary.main',
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: 'primary.main',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography
                                                component="div"
                                                variant="caption"
                                                sx={{
                                                    lineHeight: 1,
                                                    fontSize: 'caption.fontSize',
                                                    fontFamily: 'monospace, monospace',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {exportResult.token}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                    <IconButton
                                        size="small"
                                        onClick={() => copyText(exportResult.token)}
                                        color={
                                            copyState.status === 'error'
                                                ? 'error'
                                                : copyState.status === 'success'
                                                ? 'success'
                                                : 'primary'
                                        }
                                    >
                                        <FontAwesomeIcon
                                            size="sm"
                                            icon={
                                                copyState.status === 'error'
                                                    ? faClipboardQuestion
                                                    : copyState.status === 'success'
                                                    ? faClipboardCheck
                                                    : faClipboard
                                            }
                                        />
                                    </IconButton>
                                </Stack>
                                <TokenExpiryTimer
                                    exportResult={exportResult}
                                    onExpiry={() => setExportResult(undefined)}
                                />
                            </React.Fragment>
                        ) : creatingExport ? (
                            <CircularProgress size={30} />
                        ) : (
                            <Button
                                size="small"
                                variant="outlined"
                                disabled={creatingExport}
                                onClick={exportCharacter}
                            >
                                Export character
                            </Button>
                        )}
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}

const Characters: React.FC = () => {
    const { account } = useAccountContext()

    return (
        <React.Fragment>
            <Typography
                variant="body2"
                color="common.white"
                sx={{
                    fontSize: {
                        xs: 'body2.fontSize',
                        sm: 'body1.fontSize'
                    }
                }}
            >
                Text about Cubicle 7 and what to do with the character export token goes here. THIS
                IS DUMMY TEXT!
            </Typography>
            <Divider sx={{ mt: 3, borderColor: 'grey.800' }} />
            {(account?.characters ?? []).length > 0 ? (
                (account?.characters ?? []).map((character) => (
                    <CharacterCard
                        key={character.name}
                        accountId={account?.sub ?? ''}
                        character={character}
                    />
                ))
            ) : (
                <Typography
                    color="text.secondary"
                    sx={{
                        mt: 3,
                        fontWeight: 500,
                        fontSize: {
                            xs: 'caption.fontSize',
                            sm: 'body2.fontSize'
                        }
                    }}
                >
                    Your account does not appear to have any characters.
                </Typography>
            )}
        </React.Fragment>
    )
}

export default Characters
