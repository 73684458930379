import React from 'react'
import { Redirect } from 'react-router-dom'
import { routes, RouteComponent } from 'src/config'
import { useAccountContext } from 'src/context'
import { LoadingScreen } from 'src/components'

interface IAuthorized {
    route: RouteComponent
}

const Authorized: React.FC<IAuthorized> = ({ route }) => {
    const { authorized, registered, loading } = useAccountContext()

    React.useEffect(() => {
        document.title = route.title
    }, [route])

    if (route.action) {
        route.action()
    }

    if (loading) {
        return <LoadingScreen />
    } else if (route.restricted) {
        if (!authorized) {
            return <Redirect to={routes.login} />
        }

        if (!registered) {
            return routes[route.path] === routes.register ? (
                <route.component />
            ) : (
                <Redirect to={routes.register} />
            )
        }

        return routes[route.path] === routes.register ? (
            <Redirect to={routes.dashboard} />
        ) : (
            <route.component />
        )
    } else {
        return <route.component />
    }
}

export default Authorized
