import React from 'react'
import { createXboxURL } from 'src/config'
import { LoadingScreen } from 'src/components'

const XboxLogin: React.FC = () => {
    React.useEffect(() => {
        window.location.replace(createXboxURL('login'))
    }, [])

    return <LoadingScreen />
}

export default XboxLogin
