import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAccountContext, useSnackbarContext } from 'src/context'
import { delay, setLinkingData, useURLSearchParams } from 'src/utils'
import { LoadingScreen } from 'src/components'
import { linkingStart } from 'src/transfer'
import { routes } from 'src/config'

const TwitchLinkingHandler: React.FC = () => {
    const { get } = useURLSearchParams()
    const { dispatch } = useSnackbarContext()
    const { account, loading } = useAccountContext()
    const { replace } = useHistory()
    const code = get('code')
    const ref = React.useRef<boolean>(true)

    const runAsync = React.useCallback(
        async (mounted: boolean): Promise<void> => {
            await linkingStart({
                mounted,
                token: code,
                platform: 'twitch',
                next: ({ error, data, errorCode }) => {
                    if (account?.sub && data?.LinkingToken) {
                        setLinkingData({
                            Sub: account?.sub,
                            LinkingToken: data.LinkingToken
                        })
                    } else if (errorCode === 409) {
                        dispatch({
                            severity: 'error',
                            content: 'Twitch account already linked to another Fatshark account'
                        })
                    } else {
                        dispatch({
                            severity: 'error',
                            content: 'Failed to sign in'
                        })
                    }

                    replace(routes.twitchDrops)
                }
            })
        },
        [code, account, dispatch, replace]
    )

    React.useEffect(() => {
        async function run(): Promise<void> {
            await delay(1000)
            while (loading) {}
            runAsync(ref.current)
        }

        run()

        return () => {
            ref.current = false
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <LoadingScreen title="Signing in" />
}

export default TwitchLinkingHandler
